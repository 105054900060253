import React from "react";
import { Configuration } from "./Configuration/Configuration";
import { Data } from "./Data/Data";
import { IApplicationContext } from "./IApplicationContext";

export const defaults = {
  Configuration: new Configuration(),
  Data: new Data(),
};

export default React.createContext<IApplicationContext>(defaults);
