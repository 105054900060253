import { ICustomer } from "../../Types/ICustomer";
import { IProduct } from "../../Types/IProduct";
import { ISupplier } from "../../Types/ISupplier";
import { IData } from "./IData";

let products: IProduct[] = [];
let supplier: ISupplier[] = [];
let customers: ICustomer[] = [];

export class Data implements IData {
  public getProducts(): IProduct[] {
    return products;
  }
  public setProducts(p: IProduct[]) {
    products = p;
  }
  public getSuppliers(): ISupplier[] {
    return supplier;
  }
  public setSuppliers(p: ISupplier[]) {
    supplier = p;
  }

  public setCustomers(c: ICustomer[]) {
    customers = c;
  }
  public getCustomers(): ICustomer[] {
    return customers;
  }
}
