import React, { useContext, useEffect } from "react";
import "./style.css";
import {
  Button,
  Col,
  Container,
  Form,
  Modal,
  Navbar,
  Row,
  Tab,
  Tabs,
} from "react-bootstrap";
import { Dropdown, Table } from "semantic-ui-react";
import ApplicationContext from "../../../Context/ApplicationContext";

interface ICustomer {
  id: number;
  name: string;
}

interface IProps {
  customerId?: number;
  show: boolean;
  onClose: () => void;
  setSelectedCustomer: (customer: ICustomer) => void;
}

interface ITableData {
  key: number;
  value: number;
  text: string;
}

export const Customer: React.FC<IProps> = (props: IProps): JSX.Element => {
  const context = useContext(ApplicationContext);
  const [isCreditCustomer, setIsCreditCustomer] = React.useState(true);
  const [customers, setCustomers] = React.useState<ICustomer[]>([]);
  const [dropData, setDropData] = React.useState<ITableData[]>([]);
  const [name, setName] = React.useState("");

  useEffect(() => {
    const wali: ICustomer = {
      id: 1,
      name: "Asad",
    };
    const saif: ICustomer = {
      id: 2,
      name: "Saif",
    };

    context.Data.setCustomers([saif, wali]);

    const customers = context.Data.getCustomers().map(function (item) {
      return {
        key: item.id,
        value: item.id,
        text: item.name,
      };
    });

    setDropData(customers);
  }, [context.Data]);

  function findCustomerById(id: number) {
    return context.Data.getCustomers().find((element) => {
      return element.id === id;
    });
  }

  function onCustomerSelected(event: any, data: any) {
    const customer = findCustomerById(data.value);

    if (customer === undefined) {
      return;
    }

    props.setSelectedCustomer({
      id: data.value,
      name: customer.name,
    });

    props.onClose();
  }

  function onCashCustomer() {
    props.setSelectedCustomer({
      id: -999,
      name: name,
    });

    props.onClose();
  }

  const modalCustomer = (): JSX.Element => {
    return (
      <Modal show={props.show} onHide={() => props.onClose()}>
        <Modal.Header closeButton>
          <Modal.Title>Select customer</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Tabs
            defaultActiveKey="credit"
            id="uncontrolled-tab-example"
            className="mb-3"
          >
            <Tab
              eventKey="credit"
              title="Credit"
              onClick={() => setIsCreditCustomer(false)}
            >
              <Dropdown
                className="input-group input-group-lg"
                clearable={true}
                fluid
                search
                selection
                options={dropData}
                placeholder="Search customer"
                onChange={onCustomerSelected}
              />
            </Tab>
            <Tab
              eventKey="cash"
              title="Cash"
              onClick={() => setIsCreditCustomer(false)}
            >
              <Form>
                <Form.Group className="mb-3">
                  <Form.Label>Name</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="name"
                    onChange={(e) => setName(e.target.value)}
                    required
                  />
                </Form.Group>
              </Form>
            </Tab>
          </Tabs>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary btn-lg" onClick={() => props.onClose()}>
            Close
          </Button>

          {isCreditCustomer ? (
            <></>
          ) : (
            <Button variant="primary btn-lg" onClick={(e) => onCashCustomer()}>
              Select
            </Button>
          )}
        </Modal.Footer>
      </Modal>
    );
  };

  return (
    <div className="wrapper">
      <div>{modalCustomer()}</div>
    </div>
  );
};
