import React, { useContext, useEffect } from "react";
import "./Style.css";
import { Button, Form, Modal } from "react-bootstrap";
import { Dropdown, Table } from "semantic-ui-react";
import ApplicationContext from "../../Context/ApplicationContext";
import { NewSupplier } from "../../Services/Suppliers";
import { ISupplier } from "../../Types/ISupplier";

interface ITableData {
  key: number;
  value: number;
  text: string;
}

export const Supplier: React.FC = (): JSX.Element => {
  const context = useContext(ApplicationContext);
  const [showAddSupplier, setShowAddSupplier] = React.useState(false);

  const [name, setName] = React.useState("");
  const [address, setAddress] = React.useState("");
  const [postcode, setPostcode] = React.useState("");
  const [notes, setNotes] = React.useState("");

  const [tableData, setTableData] = React.useState<ITableData[]>([]);

  useEffect(() => {
    const data = context.Data.getSuppliers().map(function (item) {
      return {
        key: item.id,
        value: item.id,
        text: item.name + ", " + item.address,
      };
    });

    setTableData(data);
  }, [context.Data]);

  async function onSupplierCreated() {
    const supplier: ISupplier = {
      name: name,
      address: address,
      postcode: postcode,
      notes: notes,
      createdAt: new Date(),
      createdBy: "support",
      id: 0,
      isDeleted: false,
      parentId: 0,
    };

    const response = await NewSupplier(supplier);

    if (!response) {
      return;
    }

    if (response.length === 0) {
      return;
    }

    context.Data.setSuppliers(response);

    setShowAddSupplier(false);
  }

  function onCreateSuppler() {
    setShowAddSupplier(true);
  }

  function onSupplierSelected(event: any, data: any) {
    console.log(data);
    console.log(data.value);
  }

  function onAddSupplierClose() {
    setShowAddSupplier(false);
  }

  function tableRowClicked(id: number) {
    console.log(id);
  }

  const SupplierOptions = (): JSX.Element => {
    return (
      <div>
        <div className="card">
          <h5 className="card-header">Supplier</h5>
          <div className="card-body">
            <div className="optionCenter">
              <div className="d-grid gap-2 optionsPadding">
                <Button variant="primary" size="lg" onClick={onCreateSuppler}>
                  New Supplier
                </Button>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  };

  const modalAddSupplier = (): JSX.Element => {
    return (
      <Modal show={showAddSupplier} onHide={onAddSupplierClose}>
        <Modal.Header closeButton>
          <Modal.Title>New Supplier</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <Form.Group className="mb-3">
              <Form.Label>Name</Form.Label>
              <Form.Control
                type="text"
                placeholder="name"
                onChange={(e) => setName(e.target.value)}
              />
            </Form.Group>
            <Form.Group className="mb-3">
              <Form.Label>Address</Form.Label>
              <Form.Control
                type="text"
                placeholder="address"
                onChange={(e) => setAddress(e.target.value)}
              />
            </Form.Group>
            <Form.Group className="mb-3">
              <Form.Label>Postcode</Form.Label>
              <Form.Control
                type="text"
                placeholder="postcode"
                onChange={(e) => setPostcode(e.target.value)}
              />
            </Form.Group>
            <Form.Group className="mb-3">
              <Form.Label>Notes</Form.Label>
              <Form.Control
                type="text"
                placeholder="notes"
                onChange={(e) => setNotes(e.target.value)}
              />
            </Form.Group>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={onAddSupplierClose}>
            Cancel
          </Button>
          <Button variant="primary" onClick={onSupplierCreated}>
            Add Supplier
          </Button>
        </Modal.Footer>
      </Modal>
    );
  };

  const supplierTable = () => (
    <div>
      <Table unstackable selectable color={"black"}>
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell>Name</Table.HeaderCell>
            <Table.HeaderCell>Address</Table.HeaderCell>
            <Table.HeaderCell>Notes</Table.HeaderCell>
          </Table.Row>
        </Table.Header>

        <Table.Body>
          {context.Data.getSuppliers().map((data) => (
            <Table.Row
              positive
              onClick={() => {
                tableRowClicked(data.id);
              }}
            >
              <Table.Cell>{data.name}</Table.Cell>
              <Table.Cell>{data.address}</Table.Cell>
              <Table.Cell>{data.postcode}</Table.Cell>
              <Table.Cell>{data.notes}</Table.Cell>
            </Table.Row>
          ))}
        </Table.Body>
      </Table>
    </div>
  );

  const suppliers = (): JSX.Element => {
    return (
      <div>
        <div className="card">
          <h5 className="card-header">All suppliers</h5>
          <div className="card-body">
            <p className="card-text"></p>
            <div>
              <div>
                <Dropdown
                  clearable
                  fluid
                  search
                  selection
                  options={tableData}
                  placeholder="Search supplier"
                  onChange={onSupplierSelected}
                />
              </div>
              <div>{supplierTable()}</div>
            </div>
          </div>
        </div>
      </div>
    );
  };

  return (
    <div className="wrapper">
      <div>{SupplierOptions()}</div>
      <div>{suppliers()}</div>
      <div>{modalAddSupplier()}</div>
    </div>
  );
};
