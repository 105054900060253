import React, { useContext, useEffect } from "react";
import "./Style.css";
import { Button, Form, Modal } from "react-bootstrap";
import { Dropdown, Table } from "semantic-ui-react";
import ApplicationContext from "../../Context/ApplicationContext";
import { IProduct } from "../../Types/IProduct";
import { NewProduct } from "../../Services/Products";

interface ITableData {
  key: number;
  value: number;
  text: string;
}

export const Product: React.FC = (): JSX.Element => {
  const context = useContext(ApplicationContext);
  const [showModal, setShowModal] = React.useState(false);

  const [description, setDescription] = React.useState("");
  const [size, setSize] = React.useState("");
  const [qty, setQty] = React.useState(Number);
  const [costPrice, setCostPrice] = React.useState(0);
  const [salePrice, setSalePrice] = React.useState(0);
  const [supplierId, setSupplierId] = React.useState(Number);

  const [saleAllowed, setSaleAllowed] = React.useState(Boolean);
  const [notes, setNotes] = React.useState("");

  const [descriptionValid, setDescriptionValid] = React.useState(true);
  const [sizeValid, setSizeValid] = React.useState(true);
  const [qtyValid, setQtyValid] = React.useState(true);
  const [costPriceValid, setCostPriceValid] = React.useState(true);
  const [salePriceValid, setSalePriceValid] = React.useState(true);
  const [supplierIdValid, setSupplierIdValid] = React.useState(true);

  const [productData, setProductData] = React.useState<ITableData[]>([]);
  const [supplierData, setSupplierData] = React.useState<ITableData[]>([]);

  useEffect(() => {
    const pData = context.Data.getProducts().map(function (item) {
      return {
        key: item.id,
        value: item.id,
        text: item.description + ", " + item.size,
      };
    });
    const sData = context.Data.getSuppliers().map(function (item) {
      return {
        key: item.id,
        value: item.id,
        text: item.name + ", " + item.address,
      };
    });

    setProductData(pData);
    setSupplierData(sData);
  }, [context.Data]);

  function setDefaults() {
    setDescriptionValid(true);
    setSizeValid(true);
    setQtyValid(true);
    setCostPriceValid(true);
    setSalePriceValid(true);
    setSupplierIdValid(true);
  }

  function validateForm() {
    setDefaults();

    let ok = true;

    console.log("i get here my value is : " + supplierId);
    if (supplierId < -1) {
      setSupplierIdValid(false);
      ok = false;
    }

    if (description === "") {
      setDescriptionValid(false);
      ok = false;
    }

    if (size === "") {
      setSizeValid(false);
      ok = false;
    }

    if (qty < -1) {
      setQtyValid(false);
      ok = false;
    }

    if (costPrice < 0) {
      console.log("-------------->   Cost price :: " + costPrice);
      setCostPriceValid(false);
      ok = false;
    }

    if (salePrice < 0) {
      console.log("-------------->   Cost price :: " + costPrice);
      ok = false;
    }

    return ok;
  }

  async function onSubmit() {
    const isValid = validateForm();

    if (!isValid) {
      return;
    }

    const product: IProduct = {
      costPrice: costPrice,
      createdAt: new Date(),
      createdBy: "logged in user",
      description: description,
      id: -1,
      guid: "ac470934-bd83-4122-a7b9-1c640f1ad5c5",
      isDeleted: false,
      notes: notes,
      parentId: -1,
      qty: qty,
      salePrice: salePrice,
      size: size,
      supplierId: supplierId,
      saleAllowed: saleAllowed,
    };

    const response = await NewProduct(product);

    if (!response) {
      return;
    }

    if (response.length === 0) {
      return;
    }

    context.Data.setProducts(response);
    setShowModal(false);
  }

  function onSupplierSelected(event: any, data: any) {
    console.log(data.value);
    setSupplierId(data.value);
  }

  function closeModal() {
    setShowModal(false);
  }

  function tableRowClicked(id: number) {
    console.log(id);
  }

  function showAddModal() {
    setDefaults();
    setShowModal(true);
  }

  const cardProduct = (): JSX.Element => {
    return (
      <div>
        <div className="card">
          <h5 className="card-header">Product</h5>
          <div className="card-body">
            <div className="optionCenter">
              <div className="d-grid gap-2 optionsPadding">
                <Button
                  variant="primary"
                  size="lg"
                  onClick={() => showAddModal()}
                >
                  New Product
                </Button>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  };

  const modalProduct = (): JSX.Element => {
    return (
      <Modal show={showModal} onHide={closeModal}>
        <Modal.Header closeButton>
          <Modal.Title>New Product</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <Form.Group className="mb-3">
              <Form.Label>Supplier</Form.Label>
              <Dropdown
                clearable
                fluid
                search
                selection
                options={supplierData}
                placeholder="Search supplier"
                error={!supplierIdValid}
                onChange={onSupplierSelected}
              />
            </Form.Group>

            <Form.Group className="mb-3">
              <Form.Label>Description</Form.Label>
              <Form.Control
                type="text"
                placeholder="name"
                onChange={(e) => setDescription(e.target.value)}
                required
                isInvalid={!descriptionValid}
              />
            </Form.Group>
            <Form.Group className="mb-3">
              <Form.Label>Size</Form.Label>
              <Form.Control
                type="text"
                placeholder="Size"
                onChange={(e) => setSize(e.target.value)}
                isInvalid={!sizeValid}
              />
            </Form.Group>
            <Form.Group className="mb-3">
              <Form.Label>Qty</Form.Label>
              <Form.Control
                type="number"
                placeholder="Quantity"
                required={true}
                onChange={(e) => setQty(parseFloat(e.target.value))}
                isInvalid={!qtyValid}
              />
            </Form.Group>
            <Form.Group className="mb-3">
              <Form.Label>Cost Price</Form.Label>
              <Form.Control
                type="number"
                placeholder="Cost price"
                onChange={(e) => setCostPrice(parseFloat(e.target.value))}
                isInvalid={!costPriceValid}
              />
            </Form.Group>
            <Form.Group className="mb-3">
              <Form.Label>Sale Price</Form.Label>
              <Form.Control
                type="number"
                placeholder="Sale price"
                onChange={(e) => setSalePrice(parseFloat(e.target.value))}
                isInvalid={!salePriceValid}
              />
            </Form.Group>
            <Form.Group className="mb-3">
              <Form.Label>Notes</Form.Label>
              <Form.Control
                type="text"
                placeholder="notes"
                onChange={(e) => setNotes(e.target.value)}
                value={notes}
              />
            </Form.Group>
            <Form.Group className="mb-3">
              <Form.Check
                type="checkbox"
                label="Sale ready"
                onChange={(e) => setSaleAllowed(Boolean(e.target.value))}
                checked={saleAllowed}
              />
            </Form.Group>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={closeModal}>
            Cancel
          </Button>
          <Button type="submit" variant="primary" onClick={onSubmit}>
            Add Product
          </Button>
        </Modal.Footer>
      </Modal>
    );
  };

  const tableData = () => (
    <div>
      <Table unstackable selectable color={"black"}>
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell>Description</Table.HeaderCell>
            <Table.HeaderCell>Size</Table.HeaderCell>
            <Table.HeaderCell>Supplier</Table.HeaderCell>
            <Table.HeaderCell>Qty</Table.HeaderCell>
            <Table.HeaderCell>Cost Price</Table.HeaderCell>
            <Table.HeaderCell>Price</Table.HeaderCell>
            <Table.HeaderCell>Notes</Table.HeaderCell>
            <Table.HeaderCell>Sale Allowed</Table.HeaderCell>
          </Table.Row>
        </Table.Header>

        <Table.Body>
          {context.Data.getProducts().map((data) => (
            <Table.Row
              positive
              onClick={() => {
                tableRowClicked(data.id);
              }}
            >
              <Table.Cell>{data.description}</Table.Cell>
              <Table.Cell>{data.size}</Table.Cell>
              <Table.Cell>{data.supplierId}</Table.Cell>
              <Table.Cell>{data.qty}</Table.Cell>
              <Table.Cell>{data.costPrice}</Table.Cell>
              <Table.Cell>{data.salePrice}</Table.Cell>
              <Table.Cell>{data.notes}</Table.Cell>
              <Table.Cell>{data.saleAllowed ? <>Yes</> : <>No</>}</Table.Cell>
            </Table.Row>
          ))}
        </Table.Body>
      </Table>
    </div>
  );

  const products = (): JSX.Element => {
    return (
      <div>
        <div className="card">
          <h5 className="card-header">Products</h5>
          <div className="card-body">
            <p className="card-text"></p>
            <div>
              <div>
                <Dropdown
                  clearable
                  fluid
                  search
                  selection
                  options={supplierData}
                  placeholder="Search product"
                  // onChange={onSupplierSelected}
                />
              </div>
              <div>{tableData()}</div>
            </div>
          </div>
        </div>
      </div>
    );
  };

  return (
    <div className="wrapper">
      <div>{cardProduct()}</div>
      <div>{modalProduct()}</div>
      <div>{products()}</div>
    </div>
  );
};
