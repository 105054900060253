import React from "react";
import { Epos } from "./Components";
import { IEposProps } from "./Components/IEposProps";
import ApplicationContext, { defaults } from "./Context/ApplicationContext";
import "bootstrap/dist/css/bootstrap.min.css";

function App() {
  const eposProps: IEposProps = {
    dateTime: new Date(),
  };

  return (
    <div>
      <ApplicationContext.Provider value={defaults}>
        <Epos {...eposProps} />
      </ApplicationContext.Provider>
    </div>
  );
}

export default App;
