import { AxiosResponse } from "axios";
import { IProduct } from "../Types/IProduct";
import { API } from "./ServiceHelper";

export const NewProduct = (product: IProduct): Promise<IProduct[] | void> => {
  return API.post("Products/Add", product)
    .then((response: AxiosResponse<IProduct[]>) => {
      console.log("New product called @ " + new Date());
      console.log(response.data);
      return response.data;
    })
    .catch(function (error: any) {
      console.log(error);
      return;
    });
};

export const GetProducts = (): Promise<IProduct[] | void> => {
  return API.get("Products")
    .then((response: AxiosResponse<IProduct[]>) => {
      //const data: IProduct[] = [];

      console.log("Product api called @ " + new Date());
      console.log(response.data);

      // response.data.forEach((p: IProduct) => {
      //   const product: IProduct = {
      //     Id: p.Id,
      //     description: p.description,
      //     size: p.size,
      //     supplierId: p.supplierId,
      //     qty: p.qty,
      //     costPrice: p.costPrice,
      //     salePrice: p.salePrice,
      //     notes: p.notes,
      //     createdBy: p.createdBy,
      //     createdAt: new Date(p.createdAt),
      //     isDeleted: p.isDeleted,
      //     guid: p.guid,
      //     parentId: p.parentId,
      //   };

      //   data.push(product);
      // });

      //const test = JSON.stringify(response.data);
      return response.data;
    })
    .catch(function (error: any) {
      console.log("api error @ " + new Date());
      console.log(error);
      console.log(error.response);

      if (error.response) {
        // Request made and server responded
        console.log(error.response.data);
        console.log(error.response.status);
        console.log(error.response.headers);
      } else if (error.request) {
        // The request was made but no response was received
        console.log(error.request);
      } else {
        // Something happened in setting up the request that triggered an Error
        console.log("Error", error.message);
      }

      return;
    });
};
