import React, { useContext, useEffect } from "react";
import {
  Button,
  Container,
  Form,
  Nav,
  Navbar,
  NavDropdown,
  Offcanvas,
} from "react-bootstrap";
import { IEposProps } from "./IEposProps";
import { Login } from "./Login/index";
import "./style.css";
import { Sales } from "./Sales";
import { Consignment } from "./Consignment";
import { Goods } from "./Goods";
import { Search } from "./Search";
import { GetProducts } from "../Services/Products";
import { Supplier } from "./Supplier";
import { GetSuppliers } from "../Services/Suppliers";
import ApplicationContext from "../Context/ApplicationContext";
import { Product } from "./Product";

export const Epos: React.FC<IEposProps> = (props: IEposProps): JSX.Element => {
  const context = useContext(ApplicationContext);

  const [loginView, setLoginView] = React.useState(true);
  const [salesView, setSalesView] = React.useState(false);
  const [consignmentView, setConsignmentView] = React.useState(false);
  const [goodInView, setGoodInView] = React.useState(false);
  const [searchView, setSearchView] = React.useState(false);
  const [supplierView, setSupplierView] = React.useState(false);
  const [productView, setProductView] = React.useState(false);
  const [loading, setLoading] = React.useState(false);

  const [showMenu, setShowMenu] = React.useState(false);

  async function LoadProducts() {
    const response = await GetProducts();

    if (!response) {
      return;
    }

    if (response.length === 0) {
      return;
    }

    context.Data.setProducts(response);
  }

  async function LoadSuppliers() {
    const response = await GetSuppliers();

    if (!response) {
      return;
    }

    if (response.length === 0) {
      return;
    }

    context.Data.setSuppliers(response);
  }

  async function LoadData() {
    setLoading(true);
    LoadProducts();
    LoadSuppliers();
    setLoading(false);
  }

  useEffect(() => {
    LoadData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const LoginSuccessful = () => {
    clearViews();
    setSalesView(true);
    setShowMenu(false);
  };

  function clearViews() {
    setLoginView(false);
    setSalesView(false);
    setConsignmentView(false);
    setGoodInView(false);
    setSearchView(false);
    setSupplierView(false);
    setProductView(false);
    closeNav();
  }
  function Logout() {
    clearViews();
    setLoginView(true);
  }
  function SalesView() {
    clearViews();
    setSalesView(true);
  }
  function ConsignmentView() {
    clearViews();
    setConsignmentView(true);
  }
  function GoodInView() {
    clearViews();
    setGoodInView(true);
  }
  function SearchView() {
    clearViews();
    setSearchView(true);
  }
  function SupplierView() {
    clearViews();
    setSupplierView(true);
  }
  function ProductView() {
    clearViews();
    setProductView(true);
  }

  const closeNav = () => {
    setShowMenu(showMenu ? false : true);
  };

  const mainMenu = (): JSX.Element => (
    <Navbar
      key="mainMenu"
      expand={false}
      expanded={showMenu}
      bg="light"
      className="mb-3"
    >
      <Container fluid>
        <Navbar.Brand href="#">EPOS</Navbar.Brand>
        <Navbar.Toggle
          onClick={closeNav}
          aria-controls={`offcanvasNavbar-expand-${showMenu}`}
        />
        <Navbar.Offcanvas
          id={`offcanvasNavbar-expand-${showMenu}`}
          aria-labelledby={`offcanvasNavbarLabel-expand-${showMenu}`}
          placement="end"
        >
          <Offcanvas.Header closeButton onClick={closeNav}>
            <Offcanvas.Title id={`offcanvasNavbarLabel-expand-${showMenu}`}>
              Menu
            </Offcanvas.Title>
          </Offcanvas.Header>
          <Offcanvas.Body>
            <Nav className="justify-content-end flex-grow-1 pe-3">
              <Nav.Link onClick={SalesView}>Sales</Nav.Link>
              <Nav.Link onClick={SupplierView}>Suppliers</Nav.Link>
              <Nav.Link onClick={ProductView}>Products</Nav.Link>
              <Nav.Link onClick={ConsignmentView}>Consignment</Nav.Link>
              <Nav.Link onClick={GoodInView}>Goods in</Nav.Link>
              <Nav.Link onClick={SearchView}>Search</Nav.Link>
              <Nav.Link onClick={Logout}>Logout</Nav.Link>
            </Nav>
          </Offcanvas.Body>
        </Navbar.Offcanvas>
      </Container>
    </Navbar>
  );

  const epos = (): JSX.Element => {
    return (
      <div>
        <div className="menuBar">{loginView ? <></> : mainMenu()} </div>
        <div>
          {loginView ? <Login setLoginViewable={LoginSuccessful} /> : <></>}
        </div>
        <div>{salesView ? <Sales /> : <></>}</div>
        <div>{consignmentView ? <Consignment /> : <></>}</div>
        <div>{goodInView ? <Goods /> : <></>}</div>
        <div>{searchView ? <Search /> : <></>}</div>
        <div>{supplierView ? <Supplier /> : <></>}</div>
        <div>{productView ? <Product /> : <></>}</div>
      </div>
    );
  };

  return <div>{epos()}</div>;
};
