import axios from "axios";

export const API = axios.create({
  baseURL: "https://api.aleaf.co.uk/",
  timeout: 10000,
  headers: {
    "Content-Type": "application/json",
  },
  // headers: { token: "1234567890" },
  validateStatus: () => true,
});
