import React, { useContext, useEffect } from "react";
import "./Style.css";
import {
  Button,
  Col,
  Container,
  Form,
  Modal,
  Navbar,
  Row,
} from "react-bootstrap";
import { Dropdown, Table } from "semantic-ui-react";
import ApplicationContext from "../../Context/ApplicationContext";
import { Customer } from "./Customer";

interface ITableData {
  key: number;
  value: number;
  text: string;
}

interface ISelectedItems {
  id: string;
  name: string;
  qty: number;
  price: number;
  total: number;
}

interface ICustomer {
  id: number;
  name: string;
}

const defaultCustomer: ICustomer = {
  id: -999,
  name: "[]",
};

export const Sales: React.FC = (): JSX.Element => {
  const context = useContext(ApplicationContext);
  const [selectedProductId, setSelectedProductId] = React.useState(0);
  const [selectedReceiptItemId, SetSelectedReceiptItemId] = React.useState("");
  const [showAddProduct, setShowAddProduct] = React.useState(false);
  const [showOptions, setShowOptions] = React.useState(false);

  const [showComplete, setShowComplete] = React.useState(false);
  const [showUpdateProduct, setShowUpdateProduct] = React.useState(false);
  const [product, setProduct] = React.useState<ITableData[]>([]);
  const [receiptItems, setReceiptItems] = React.useState<ISelectedItems[]>([]);

  const [receiptItemsTotal, setReceiptItemsTotal] = React.useState(0);

  const [itemName, setItemName] = React.useState("");
  const [itemQty, setItemQty] = React.useState(0);
  const [itemPrice, setItemPrice] = React.useState("");

  //Customer
  const [showCustomer, setShowCustomer] = React.useState(false);
  const [customer, setCustomer] = React.useState<ICustomer>(defaultCustomer);

  function onCloseCustomer() {
    setShowCustomer(false);
  }
  function onSelectedCustomer(c: ICustomer) {
    setCustomer(c);
    setShowCustomer(false);
  }

  function findSupplierById(id: number) {
    return context.Data.getSuppliers().find((element) => {
      return element.id === id;
    });
  }

  function findProductById(id: number) {
    return context.Data.getProducts().find((element) => {
      return element.id === id;
    });
  }

  function calculateTotal() {
    let itemsTotal = 0;
    receiptItems.forEach((el) => {
      itemsTotal += el.total;
    });
    setReceiptItemsTotal(itemsTotal);
  }

  useEffect(() => {
    const pData = context.Data.getProducts().map(function(item) {
      const supplier = findSupplierById(item.supplierId);
      return {
        key: item.id,
        value: item.id,
        text: item.description + ", " + supplier?.name,
      };
    });
    setProduct(pData);
  }, [context.Data]);

  useEffect(() => {
    calculateTotal();
  }, [receiptItems]);

  function completeAction(e: any) {
    e.preventDefault();
    showModalComplete();
  }

  function onProductSelected(event: any, data: any) {
    //e.preventDefault();
    setSelectedProductId(data.value);

    const product = context.Data.getProducts().find((element) => {
      return element.id === data.value;
    });
    const supplier = context.Data.getSuppliers().find((element) => {
      return element.id === product?.supplierId;
    });

    if (product === undefined) {
      return;
    }

    setItemName(product.description + ", " + supplier?.name);
    setItemPrice(product.salePrice.toFixed(2).toString());

    setShowAddProduct(true);
  }

  function closeSearchProduct() {
    setShowAddProduct(false);
  }

  function addProductToReceipt() {
    const product = findProductById(selectedProductId);

    if (product === undefined) {
      return;
    }

    let price = 0;

    if (itemPrice !== "") {
      price = parseFloat(itemPrice);
    } else {
      price = product.salePrice;
    }

    function CreateUUID() {
      return "xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx".replace(/[xy]/g, function(
        c
      ) {
        var r = (Math.random() * 16) | 0,
          v = c == "x" ? r : (r & 0x3) | 0x8;
        return v.toString(16);
      });
    }

    const receiptItem: ISelectedItems = {
      id: CreateUUID(),
      name: product ? product.description : "",
      price: price,
      qty: itemQty,
      total: price * itemQty,
    };

    setReceiptItems([...receiptItems, receiptItem]);
    setSelectedProductId(-1);
    setShowAddProduct(false);
  }

  function showAdditionalOptions() {
    setShowOptions(true);
  }
  function closeAdditionalOptions() {
    setShowOptions(false);
  }
  function showModalCustomer() {
    //setShowCustomers(true);
  }
  function hideModalCustomer() {
    //setShowCustomers(false);
  }
  function showModalComplete() {
    setShowComplete(true);
  }
  function hideModalComplete() {
    setShowComplete(false);
  }
  function removeItem(id: string) {
    const item = receiptItems.find((element) => {
      return element.id === id;
    });

    if (item === undefined) {
      return;
    }

    const idx = receiptItems.indexOf(item);
    receiptItems.splice(idx, 1);

    calculateTotal();

    setShowUpdateProduct(false);
  }
  function onReciptItemClicked(id: string) {
    SetSelectedReceiptItemId(id);

    const item = receiptItems.find((element) => {
      return element.id === id.toString();
    });

    if (item === undefined) {
      return;
    }

    setItemQty(item.qty);
    setItemPrice(item.price.toFixed(2).toString());
    setItemName(item.name);

    setShowUpdateProduct(true);
  }
  function hideModalUpdateProduct() {
    setShowUpdateProduct(false);
  }
  function updateProduct() {
    const item = receiptItems.find((element) => {
      return element.id === selectedReceiptItemId.toString();
    });

    if (item === undefined) {
      return;
    }

    const idx = receiptItems.indexOf(item);
    receiptItems.splice(idx, 1);

    let price = 0;

    if (itemPrice !== "") {
      price = parseFloat(itemPrice);
    } else {
      price = item.price;
    }

    const receiptItem: ISelectedItems = {
      id: item.id,
      name: item.name,
      price: price,
      qty: itemQty,
      total: price * itemQty,
    };

    setReceiptItems([...receiptItems, receiptItem]);
    setShowUpdateProduct(false);
  }
  function salePrice(e: any) {
    setItemPrice(e.target.value);
  }

  const SearchProduct = () => (
    <Dropdown
      className="input-group input-group-lg"
      clearable={true}
      fluid
      search
      selection
      options={product}
      placeholder="Search product"
      onChange={onProductSelected}
    />
  );

  const modalUpdateProduct = (): JSX.Element => {
    return (
      <Modal show={showUpdateProduct} onHide={hideModalUpdateProduct}>
        <Modal.Header closeButton>
          <Modal.Title>{itemName}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <Form.Group className="input-group input-group-lg">
              <Form.Label className="input-group input-group-lg">
                Qty
              </Form.Label>
              <Form.Control
                type="number"
                placeholder="Quantity"
                required={true}
                value={itemQty}
                onChange={(e) => setItemQty(parseFloat(e.target.value))}
                // isInvalid={!qtyValid}
                autoFocus={true}
              />
            </Form.Group>
            <Form.Group className="input-group input-group-lg">
              <Form.Label className="input-group input-group-lg">
                Price
              </Form.Label>
              <Form.Control
                type="number"
                placeholder="Price"
                required={true}
                value={itemPrice}
                onChange={salePrice}
                // isInvalid={!qtyValid}
              />
            </Form.Group>
          </Form>
        </Modal.Body>
        <Modal.Footer className="modal-footer justify-content-between">
          <Button
            className="mr-auto"
            variant="danger"
            onClick={(e) => removeItem(selectedReceiptItemId)}
          >
            Remove
          </Button>

          <div>
            <Button
              variant="secondary"
              onClick={(e) => setShowUpdateProduct(false)}
            >
              Cancel
            </Button>
            <> </>
            <Button
              variant="success"
              className="modal-padding"
              onClick={updateProduct}
            >
              Update
            </Button>
          </div>
        </Modal.Footer>
      </Modal>
    );
  };

  const modalComplete = (): JSX.Element => {
    return (
      <Modal show={showComplete} onHide={closeSearchProduct}>
        <Modal.Header closeButton>
          <Modal.Title>Complete</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="optionCenter">Product is added</div>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="success" onClick={hideModalComplete}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    );
  };

  // const modalCustomer = (): JSX.Element => {
  //   return (
  //     <Modal show={showCustomers} onHide={closeSearchProduct}>
  //       <Modal.Header closeButton>
  //         <Modal.Title>Select customer</Modal.Title>
  //       </Modal.Header>
  //       <Modal.Body>
  //         <div className="optionCenter">customer screen coming soon</div>
  //       </Modal.Body>
  //       <Modal.Footer>
  //         <Button variant="secondary" onClick={hideModalCustomer}>
  //           Close
  //         </Button>
  //       </Modal.Footer>
  //     </Modal>
  //   );
  // };

  const additionalOptions = (): JSX.Element => {
    return (
      <Modal show={showOptions} onHide={closeSearchProduct}>
        <Modal.Header closeButton>
          <Modal.Title>Options</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="optionCenter">
            <div className="d-grid gap-2 optionsPadding">
              <Button variant="primary" size="lg">
                Park
              </Button>
            </div>
            <div className="d-grid gap-2 optionsPadding">
              <Button variant="info" size="lg">
                Recall
              </Button>
            </div>
            <div className="d-grid gap-2 optionsPadding">
              <Button variant="danger" size="lg">
                Void
              </Button>
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={closeAdditionalOptions}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    );
  };

  const modalProduct = (): JSX.Element => {
    return (
      <Modal show={showAddProduct} onHide={closeSearchProduct}>
        <Modal.Header closeButton>
          <Modal.Title>{itemName}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <Form.Group className="input-group input-group-lg">
              <Form.Label className="input-group input-group-lg">
                Qty
              </Form.Label>
              <Form.Control
                type="number"
                placeholder="Quantity"
                required={true}
                onChange={(e) => setItemQty(parseFloat(e.target.value))}
                // isInvalid={!qtyValid}
                autoFocus={true}
              />
            </Form.Group>
            <Form.Group className="input-group input-group-lg">
              <Form.Label className="input-group input-group-lg">
                Price
              </Form.Label>
              <Form.Control
                type="number"
                placeholder="Price"
                required={true}
                value={itemPrice}
                onChange={salePrice}
                // isInvalid={!qtyValid}
              />
            </Form.Group>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={closeSearchProduct}>
            Cancel
          </Button>
          <Button variant="primary" onClick={addProductToReceipt}>
            Add Product
          </Button>
        </Modal.Footer>
      </Modal>
    );
  };

  const TableExampleSelectableCell = () => (
    <div>
      <Table unstackable selectable color={"black"}>
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell>Name</Table.HeaderCell>
            <Table.HeaderCell>Qty</Table.HeaderCell>
            <Table.HeaderCell>Price</Table.HeaderCell>
            <Table.HeaderCell>Total</Table.HeaderCell>
          </Table.Row>
        </Table.Header>

        <Table.Body>
          {receiptItems.map((data) => (
            <Table.Row
              positive
              onClick={() => {
                onReciptItemClicked(data.id);
              }}
            >
              <Table.Cell>{data.name}</Table.Cell>
              <Table.Cell>{data.qty}</Table.Cell>
              <Table.Cell>£{data.price.toFixed(2)}</Table.Cell>
              <Table.Cell>£{data.total.toFixed(2)}</Table.Cell>
            </Table.Row>
          ))}
        </Table.Body>
      </Table>
    </div>
  );

  const receipt = (): JSX.Element => {
    return (
      <div>
        <div className="card">
          <h5 className="card-header">
            <Navbar>
              <Container>
                <Navbar.Brand href="#home">{customer.name}</Navbar.Brand>
                <Navbar.Toggle />
                <Navbar.Collapse className="justify-content-end">
                  <Navbar.Text>
                    <Button
                      variant="primary btn-lg"
                      onClick={(o) => setShowCustomer(true)}
                    >
                      Customers
                    </Button>{" "}
                  </Navbar.Text>
                </Navbar.Collapse>
              </Container>
            </Navbar>
          </h5>
          <div className="card-body">
            <p className="card-text"></p>
            <div>
              <div>{SearchProduct()}</div>
              <div>{TableExampleSelectableCell()}</div>
              <div>
                <div className="row">
                  <div className="col"></div>
                  <div className="col totalAlightRight">
                    <div>
                      <Row>
                        <Col></Col>
                        <Col md="auto"></Col>
                        <Col xs lg="2" className="paddingTop">
                          Total
                        </Col>
                        <Col xs lg="2">
                          <div style={{ maxWidth: 70, textAlign: "right" }}>
                            <Form.Control
                              type="text"
                              value={receiptItemsTotal.toFixed(2)}
                              placeholder="0"
                            />
                          </div>
                        </Col>
                      </Row>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div>
              <div className="row">
                <div className="col-8">
                  <Button
                    variant="primary btn-lg"
                    onClick={showAdditionalOptions}
                  >
                    Options
                  </Button>
                </div>
                <div className="col-4">
                  <div className="col textAlignRight">
                    <Button variant="success btn-lg" onClick={completeAction}>
                      Complete
                    </Button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  };

  // const customer = (): JSX.Element => {
  //   return (
  //     <div>
  //       <div className="card">
  //         <h5 className="card-header">Customer</h5>
  //         <div className="card-body">
  //           <h5 className="card-title">Saif Ul Islam</h5>
  //           <p className="card-text">Barkerend Road, Bradford, BD</p>
  //           <Button variant="primary btn-lg" onClick={showModalCustomer}>
  //             Change
  //           </Button>
  //         </div>
  //       </div>
  //     </div>
  //   );
  // };

  return (
    <div className="wrapper">
      {/* <div>{customer()}</div> */}
      <div>{receipt()}</div>
      <div>{modalProduct()}</div>
      <div>{additionalOptions()}</div>
      <div>
        <Customer
          show={showCustomer}
          onClose={onCloseCustomer}
          setSelectedCustomer={onSelectedCustomer}
        />
      </div>
      <div>{modalComplete()}</div>
      <div>{modalUpdateProduct()}</div>
    </div>
  );
};
