import { AxiosResponse } from "axios";
import { ISupplier } from "../Types/ISupplier";
import { API } from "./ServiceHelper";

export const NewSupplier = (
  supplier: ISupplier
): Promise<ISupplier[] | void> => {
  return API.post("Suppliers", supplier)
    .then((response: AxiosResponse<ISupplier[]>) => {
      console.log("new suppliers api called @ " + new Date());
      const data: ISupplier[] = [];

      response.data.forEach((s: ISupplier) => {
        const supplier: ISupplier = {
          id: s.id,
          name: s.name,
          address: s.address,
          postcode: s.postcode,
          notes: s.notes,
          createdBy: s.createdBy,
          createdAt: new Date(s.createdAt),
          isDeleted: s.isDeleted,
          parentId: s.parentId,
        };

        data.push(supplier);
      });
      return data;
    })
    .catch(function (error: any) {
      console.log(error);
      return;
    });
};

// if (error.response) {
//   // Request made and server responded
//   console.log(error.response.data);
//   console.log(error.response.status);
//   console.log(error.response.headers);
// } else if (error.request) {
//   // The request was made but no response was received
//   console.log(error.request);
// } else {
//   // Something happened in setting up the request that triggered an Error
//   console.log("Error", error.message);
//   return false;
// });

export const GetSuppliers = (): Promise<ISupplier[] | void> => {
  return API.get("Suppliers")
    .then((response: AxiosResponse<ISupplier[]>) => {
      const data: ISupplier[] = [];

      console.log("suppliers api called @ " + new Date());
      console.log(response.data);

      response.data.forEach((s: ISupplier) => {
        const supplier: ISupplier = {
          id: s.id,
          name: s.name,
          address: s.address,
          postcode: s.postcode,
          notes: s.notes,
          createdBy: s.createdBy,
          createdAt: new Date(s.createdAt),
          isDeleted: s.isDeleted,
          parentId: s.parentId,
        };

        data.push(supplier);
      });

      //const test = JSON.stringify(response.data);
      return data;
    })
    .catch(function (error: any) {
      console.log("suppliers api error @ " + new Date());
      console.log(error);
      console.log(error.response);

      if (error.response) {
        // Request made and server responded
        console.log(error.response.data);
        console.log(error.response.status);
        console.log(error.response.headers);
      } else if (error.request) {
        // The request was made but no response was received
        console.log(error.request);
      } else {
        // Something happened in setting up the request that triggered an Error
        console.log("Error", error.message);
      }

      return;
    });
};
