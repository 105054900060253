import "./Style.css";
import { Form, Button } from "react-bootstrap";
import logo from "../Images/Logo.png";

interface IProps {
  setLoginViewable: () => void;
}

export const Login: React.FC<IProps> = (props: IProps): JSX.Element => {
  function handleSubmit(e: any) {
    e.preventDefault();
    props.setLoginViewable();
  }

  const loginForm = (): JSX.Element => {
    return (
      <div className="center">
        <div className="logo">
          <img src={logo} alt="logo" width={350} height={150} />
          <br />
        </div>
        <div>
          <Form onSubmit={handleSubmit}>
            <Form.Group
              className="mb-3 optionsPadding"
              controlId="formUsername"
            >
              <Form.Label>Username</Form.Label>
              <Form.Control type="textbox" placeholder="Username" />
            </Form.Group>
            <Form.Group className="mb-3" controlId="formPassword">
              <Form.Label>Password</Form.Label>
              <Form.Control type="password" placeholder="Password" />
            </Form.Group>
            <div className="d-grid gap-2">
              <Button variant="primary" size="lg" type="submit">
                Login
              </Button>
            </div>
          </Form>
        </div>
        <div className="contactInfo">
          <div>aleaf.co.uk</div>
          <div>07929-929070</div>
          <div>info@aleaf.co.uk</div>
        </div>
      </div>
    );
  };

  return <div>{loginForm()}</div>;
};
